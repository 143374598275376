import { render, staticRenderFns } from "./FastLogin.vue?vue&type=template&id=48ef093a&"
import script from "./FastLogin.vue?vue&type=script&lang=js&"
export * from "./FastLogin.vue?vue&type=script&lang=js&"
import style0 from "./FastLogin.vue?vue&type=style&index=0&id=48ef093a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignUp: require('/Users/eralpsoftwareweb/Desktop/Active Projects/Heocademy/Gökçe Özel/gokce-ozel-nuxt/components/SignUp/index.vue').default,Login: require('/Users/eralpsoftwareweb/Desktop/Active Projects/Heocademy/Gökçe Özel/gokce-ozel-nuxt/components/Login/index.vue').default})
