
import SignUp from '~/components/SignUp/index.vue'
import Login from '~/components/Login/index.vue'
export default {
  components: {
    SignUp,
    Login,
  },
  data() {
    return {
      activeComponent: 'SignUp',
    }
  },
  props: {
    value: {
      default: false,
    },
    item: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    title: {
      default: 'createAppointment'
    },
    description: {
      default: 'createAppointmentFirstLogin'
    },
  },
}
