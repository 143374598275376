
import { mapGetters } from 'vuex'
import InnerPageTitle from '../Shared/InnerPageTitle/index.vue'
import Checkbox from '../Shared/Checkbox.vue'
export default {
  components: { InnerPageTitle, Checkbox },
  props: {
    fast: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      checkModel: false,
      form: {
        email: '',
        fullname: '',
        password: '',
        phone: '',
        file: '',
      },
      loading: false,
      definations: [],
      alertBox: { status: false, message: '', variant: '' },
    }
  },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
    }),
  },
  methods: {
    CHECK_LENGTH(validations, key) {
      const validation = validations.find((val) => val.includes(key))
      if (validation) {
        const getLength = validation
          .substring(0, validation.length - 1)
          .split('(')[1]
        if (isNaN(Number(getLength))) return false
        else return Number(getLength)
      } else return false
    },
    async GET_FILE(e, defination, index) {
      const files = e.target.files
      if (defination.type === 'file') {
        const file = files[0]
        this.definations[index].file = file
      }
    },
    async SEND_USER_FOR_REGISTER(e) {
      e.preventDefault()
      const fd = new FormData()
      this.alertBox.status = false
      this.loading = true
      fd.append('Email', this.form.email)
      fd.append('Fullname', this.form.fullname)
      fd.append('Password', this.form.password)
      fd.append('Phone', this.form.phone)
      fd.append('File', this.form.file)
      fd.append('LangCode', this.getUserIP.countryCode)
      const rezForm = {
        CompanyId:
          this.$store.getters['seances/getCompanyRezzervationInformation'].Id, // firmanın remote idsi;
        Fullname: this.form.fullname,
        Type: 2, // 2: email 1: phone
        Phone: '', // phone bilgisi yok.
        Email: this.form.email,
        isEmail: 0,
      }
      const definations = this.definations
        .filter((def) => def.type !== 'file')
        .map((d) => ({
          code: d.code,
          dataId: null,
          dataType: 'User',
          value: d.value || "",
        }))
      const filesDefinitions = this.definations
        .filter((def) => def.type === 'file')
        .map((d) => ({
          code: d.code,
          dataId: null,
          dataType: 'User',
          file: d.file,
          value: d.value,
        }))
      this.alertBox = await this.$store.dispatch(
        'user/SEND_USER_FOR_REGISTER',
        {
          fd,
          rezForm,
          definations,
          filesDefinitions,
        }
      )
      if (this.alertBox.status && this.alertBox.variant === 'success') {
        this.$store.commit('user/AUTO_LOGIN', {
          email: this.form.email,
          password: this.form.password,
        })
        setTimeout(() => {
          if (this.fast) return this.$emit('change:component', 'Login')
          this.$router.push({
            path: this.localePath('/giris-yap'),
            query: { action: 'auto-login' },
          })
        }, 2500)
      }
      this.loading = false
    },
    async GET_DEFINATIONS() {
      const response = await this.$api.get(
        `Definitions?useType=Register&lang=${this.$i18n.locale}`
      )
      if (response.message === 'OK') {
        this.definations = response.data
          .map((d) => ({
            ...d,
            value: null,
            file: null,
            validations: d.validations.split(','),
          }))
          .filter((dat) => dat.language === this.$i18n.locale)
      } else this.definations = []
    },
    changeImage(path) {
      this.form.file = path.target.files[0]
    },
  },
  mounted() {
    this.GET_DEFINATIONS()
  },
}
